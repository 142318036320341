<template>
	<div>
		<app-layout>
			<template v-slot:header>
				<Header :title="$t('diploma_ebys_send')" :isNewButton="false" :isColumns="true"
					@filter-div-status="datatable.filterStatus = $event">
					<template v-slot:columns>
						<div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
							<b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
								:unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
								{{ column.label }}
							</b-form-checkbox>
						</div>
					</template>
				</Header>
			</template>
			<template v-slot:header-mobile>
				<HeaderMobile :title="$t('diploma_ebys_send')" :isNewButton="true" :isColumns="true"
					@new-button-click="createFormShow()" @filter-div-status="datatable.filterStatus = $event">
					<template v-slot:columns>
						<div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
							<b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
								:unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
								{{ column.label }}
							</b-form-checkbox>
						</div>
					</template>
				</HeaderMobile>
			</template>
			<datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
				<b-row>
					<b-col sm="6" md="4">
						<b-form-group :label="$t('semester')">
							<semesters-selectbox
								v-model="datatable.queryParams.filter.graduation_semester_id"></semesters-selectbox>
						</b-form-group>
						<b-form-group :label="$t('graduate_date')" class="position-relative">
							<v-date-picker v-model="datatable.queryParams.filter.graduation_date" :locale="$i18n.locale"
								is-expanded :masks="masks" :max-date="new Date()" :popover="{ 'visibility': 'click' }">

								<template v-slot="{ inputValue, inputEvents }">
									<b-input-group>
										<b-form-input :readonly="true" :value="inputValue"
											v-on="inputEvents"></b-form-input>
										<b-input-group-append>
											<b-button variant="outline-secondary" class="btn-40" disabled>
												<i class="ri-calendar-line"></i></b-button>
										</b-input-group-append>

									</b-input-group>
								</template>
							</v-date-picker>
						</b-form-group>
					</b-col>
					<b-col sm="6" md="4">
						<b-form-group :label="$t('student_numbers')">
							<lined-textarea v-model="studentNumbers" :onlyNumberAndEnter="true"
								:styles="{ height: '200px' }"></lined-textarea>
						</b-form-group>
					</b-col>
					<b-col sm="6" md="4">
						<b-form-group :label="$t('faculty')">
							<faculty-selectbox v-model="datatable.queryParams.filter.faculty_code"></faculty-selectbox>
						</b-form-group>
						<b-form-group :label="$t('program')">
							<program-selectbox :is_faculty_code_required="true"
								:faculty_code="datatable.queryParams.filter.faculty_code"
								v-model="datatable.queryParams.filter.program_code"></program-selectbox>
						</b-form-group>
					</b-col>
				</b-row>
			</datatable-filter>
			<datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
				:total="datatable.total" :queryParams="datatable.queryParams" @on-page-change="onPageChange"
				@on-sort-change="onSortChange" @on-per-page-change="onPerPageChange" v-show="datatable.showTable"
				:selectOptions="true" :selectedActionButtonText="$t('ebys_send')" @selected-bulk-action="sendToEbys"
				@on-selected-rows-change="getSelectedRows">

				<!-- diploma_ebys_id -->
			</datatable>
			<CommonModal ref="detailFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
				<template v-slot:CommonModalTitle>
					{{ $t('detail').toUpper() }}
				</template>
				<template v-slot:CommonModalContent>
					<detail-form :formObj="formObj" @updateFormSuccess="detailFormSuccess" v-if="formProcess == 'detail'" />
				</template>
			</CommonModal>
		</app-layout>
	</div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"

// Components
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import CommonModal from "@/components/elements/CommonModal";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox"
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox"
import LinedTextarea from "@/components/elements/LinedTextarea";

// Pages
import DetailForm from "./DetailForm";

// Services
import StudentProgramService from "@/services/StudentProgramService"
import DocumentRequestService from "@/services/DocumentRequestService";

// Others
import qs from 'qs'
import { EventBus } from '@/main'
import documentData from "../data/Documents";

export default {
	name: "DiplomaEbysSend",
	components: {
		AppLayout,
		Header,
		HeaderMobile,
		DatatableFilter,
		Datatable,
		CommonModal,
		SemestersSelectbox,
		LinedTextarea,
		ProgramSelectbox,
		FacultySelectbox,
		DetailForm,
	},
	metaInfo() {
		return {
			title: this.$t('diploma_ebys_send')
		}
	},
	data() {
		return {
			selectedRows: [],
			masks: {
				input: "DD-MM-YYYY",
			},
			studentNumbers: null,
			periods: [
				{ value: 1, text: this.$t('fall') },
				{ value: 2, text: this.$t('spring') },
				{ value: 3, text: this.$t('summer') },
				{ value: 4, text: this.$t('yearly') }
			],
			datatable: {
				isLoading: false,
				columns: [
					{
						label: '',
						field: 'buttons',
						html: true,
						sortable: false,
						tdClass: 'p-0 text-center w-40 align-middle',
						buttons: [
							{
								text: this.$t('view'),
								class: 'ri-search-eye-line align-middle top-minus-1 mr-3 text-muted',
								//permission: 'documentrequest_show',
								callback: (row) => {
									documentData(row, 'diploma', null).callback()
								}
							}
						]
					},
					{
						label: this.toUpperCase('id'),
						field: 'id',
						hidden: true,
					},
					{
						label: this.toUpperCase('student_number'),
						field: 'student_number',
						hidden: false,
						sortable: false
					},
					{
						label: this.toUpperCase('national_id_passport_no'),
						field: 'national_id',
						hidden: false,
						sortable: false
					},
					{
						label: this.toUpperCase('name'),
						field: 'name',
						hidden: false,
						sortable: false
					},
					{
						label: this.toUpperCase('surname'),
						field: 'surname',
						hidden: false,
						sortable: false
					},
					{
						label: this.toUpperCase('faculty'),
						field: this.getLocaleField('faculty_name'),
						hidden: false,
						sortable: false
					},
					{
						label: this.toUpperCase('program'),
						field: this.getLocaleField('program_name'),
						hidden: false,
						sortable: false
					},
					{
						label: this.toUpperCase('graduate_semester'),
						field: 'graduation_semester_academic_year',
						hidden: false,
						sortable: false
					},
					{
						label: this.toUpperCase('graduate_period'),
						field: 'graduation_semester_semester',
						hidden: false,
						sortable: false,
						formatFn: (index) => {
							if (this.periods?.[index - 1]?.text)
								return this.periods[index - 1].text
							return ""
						}
					},
					{
						label: this.toUpperCase('ebys_status'),
						field: 'diploma_ebys_id',
						hidden: false,
						sortable: false,
					},
				],
				rows: [],
				total: 0,
				filterStatus: true,
				showTable: false,
				queryParams: {
					filter: {},
					sort: '-id',
					page: 1,
					limit: 20
				}
			},
			formProcess: null,
			formObj: null,
			form: {},
		}
	},
	created() {
		this.filterSet();
		this.formClear();
	},
	methods: {
		filterSet() {
			this.datatable.queryParams.filter = {
				student_status: "102,132"
			}
			this.studentNumbers = null
		},
		filterClear() {
			this.filterSet()
			this.getRows();
		},
		filter() {
			this.getRows();
		},
		onPageChange(params) {
			this.datatable.queryParams.page = params.currentPage;
			this.getRows();
		},
		onPerPageChange(params) {
			this.datatable.queryParams.limit = params.currentPerPage;
			this.datatable.queryParams.page = 1;
			this.getRows();
		},
		changeColumn(key) {
			this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
		},
		onSortChange(params) {
			const sortType = params[0].type == 'desc' ? '-' : '';
			this.datatable.queryParams.sort = sortType + params[0].field
			this.getRows();
		},
		getRows() {
			this.datatable.showTable = true;
			this.datatable.isLoading = true;

			if (this.studentNumbers != null && this.studentNumbers != '') {
				this.datatable.queryParams.filter.student_number = this.studentNumbers.split(/\n/)
			} else {
				delete this.datatable.queryParams.filter.student_number
			}

			const config = {
				params: {
					...this.datatable.queryParams
				},
				paramsSerializer: (params) => qs.stringify(params, { encode: false })
			};
			return StudentProgramService.getAll(config)
				.then((response) => {
					this.datatable.rows = response.data.data
					this.datatable.total = response.data.pagination.total
				})
				.finally(() => {
					this.datatable.isLoading = false;
				});
		},

		// Clear
		formClear() {
			this.formObj = null
			this.formProcess = null
			this.studentNumbers = null
			this.getRows()
		},

		// Update
		detailFormShow(obj) {
			this.formObj = obj
			this.formProcess = 'detail'
			this.$refs.detailFormModal.$refs.commonModal.show()
		},
		detailFormSuccess() {
			this.$refs.detailFormModal.$refs.commonModal.hide()
			this.getRows()
			this.formClear()
		},
		exportExcel() {
			const config = {
				params: {
					...this.datatable.queryParams
				},
				paramsSerializer: (params) => qs.stringify(params, { encode: false })
			};

			DocumentRequestService.exportExcel(config).then(res => this._downloadFile(res, this.$t('document_requests_title') + '.xlsx')).catch(err => this.showErrors(err))
		},


		sendToEbys() {
			let errors = []
			this.selectedRows.forEach((itm, index) => {
				let formData = {
					student_program_id: itm.id
				}
				DocumentRequestService.diploma(formData).then(response => {
					if ((this.selectedRows.length - 1) == index) {
						this.$toast.success(this.$t('api.' + response.data.message))

					}
				}).catch(e => {
					errors.push(e)
					if (errors.length == 1) {
						this.showErrors(e, null)
					}
				})
			})

		},
		getSelectedRows(rows) {
			this.selectedRows = rows.selectedRows
		},
	},

	mounted() {
		EventBus.$on('closeDocumentModal', (event) => {
			this.$refs.detailFormModal.$refs.commonModal.hide()
		})
	}
}
</script>

